import { useRoutes } from 'react-router-dom';
import router, { getRoutes } from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useAppSelector } from 'src/app/hooks'

function App() {
  const user = localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : useAppSelector((state) => state.auth.value) 
  const routes = getRoutes(user)
  const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
 