import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserCredential } from "firebase/auth";

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: null
  },
  reducers: {
    login: (state, action) => {
      state.value = action
    }
  }
})

export const { login } = authSlice.actions
export default authSlice.reducer