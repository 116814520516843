import { Store } from "@mui/icons-material";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "src/content/auth/Auth";

export const store = configureStore({
  reducer: {
    auth: authReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch