import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Rooms from './content/pages/Components/Rooms';
import {BCM, Machines} from './content/pages/Components/Rooms/bcm';
import { Dashboard } from '@mui/icons-material';
import DashboardDetentions from './content/applications/Logs/Detention';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const Login = Loader(lazy(() => import('src/content/auth')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications
const Detentions = Loader(
  lazy(() => import('src/content/applications/Logs/Detention'))
);
const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components
const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

export function getRoutes(isLoggedIn: boolean): RouteObject[] {
  const routesPaths: RouteObject[] = [
    {
      path: "/",
      element: <BaseLayout />,
      children: [
        {
          path: '', 
          element: <Navigate to="/login" replace />
        },
        {
          path: 'login',
          element: <Login />
        }
      ]
    },
    {
      path: "/secured",
      element: isLoggedIn ? <BaseLayout /> : <Navigate to="/login" replace/> , //TODO Dashboard principal
      children: [
        {
          path: '',
          element: <Navigate to="/secured/logs/detentions" replace />
        },
        {
          path: "logs",
          element: <SidebarLayout />,
          children: [
            {
              path: "detentions",
              element: <Detentions />
            }
          ]
        },
        {
          path: 'section',
          element: <SidebarLayout/>,
          children: [
            {
              path: '',
              element: <Navigate to="rooms" />
            },
            {
              path: 'bcm',
              element: <StatusComingSoon />
            },
            {
              path: 'cut',
              element: <StatusComingSoon />
            },
            {
              path: 'conversion',
              element: <StatusComingSoon />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: isLoggedIn ? <Status404 /> : <Navigate to="/login" replace />
    }
  ] 

  return routesPaths
}

export default getRoutes;
